/**
 * 수소 특장차 운행관리
 * @author caias
 * @since 2020.09.21 - draft
 */
'use strict';

const activeClass = 'active';
const carStatusList = {
  compression: [
    {
      th1: '동작모드',
      td1: '열림',
      th2: '밑판후진',
      td2: 'ON',
    },
    {
      th1: '작동',
      td1: '연속',
      th2: '회전판역회전',
      td2: 'ON',
    },
    {
      th1: '도어상태',
      td1: '열림',
      th2: '정지',
      td2: 'ON',
    }
  ],
  food: [
    {
      th1: '리프트',
      td1: '상승/하강',
      th2: '루프도어',
      td2: '열림/닫힘',
    },
    {
      th1: '정지',
      td1: 'ON/OFF',
      th2: '배출판',
      td2: '전진/후진',
    },
    {
      th1: 'ACCEL',
      td1: 'ON/OFF',
      th2: ' ',
      td2: ' ',
    },
  ],
  road: [
    {
      th1: '브러쉬방향',
      td1: '좌측/우측',
      th2: '흡입',
      td2: '미사용/좌흡입/우흡입/흡입',
    },
    {
      th1: '메인브러쉬',
      td1: 'ON/OFF',
      th2: '쓰레기배출',
      td2: 'ON/OFF',
    },
    {
      th1: ' ',
      td1: ' ',
      th2: ' ',
      td2: ' ',
    },
  ],
  water: [
    {
      th1: ' ',
      td1: ' ',
      th2: ' ',
      td2: ' ',
    },
    {
      th1: ' ',
      td1: ' ',
      th2: ' ',
      td2: ' ',
    },
    {
      th1: ' ',
      td1: ' ',
      th2: ' ',
      td2: ' ',
    },
  ],
}

$(document).ready(function () {
  layoutHandler(init);
});

function init() {
  toggleClassHandler();
  scrollInit();
  carResultHandler();
  carSelect();
  poiToggle();
  tabToggle();
  // viewToggle();
  autoLabel();
  customSelectbox();
  gnbHandler();
};

/**
 * HEADER / FOOTER import rendering
 * @param {Function} [callback = () => {}] 레이아웃 랜더링 후 콜백
 */
function layoutHandler(callback = () => {}) {
  $('header').load('/dist/html/layout/header.html', function () {
    const $this = $(this);
    const { activeIndex } = $this.data();
    const $activeGnb = $('[data-gnb-tag=anchor]').eq(activeIndex);

    $activeGnb.addClass('active');
    $(this).find('header').unwrap('header');
    callback();
  });
  $('footer').load('/dist/html/layout/footer.html', function () {
    $(this).find('footer').unwrap('footer');
  });
}

/**
 * 단순 버튼 클레스 토글
 */
function toggleClassHandler() {
  $('[data-button-toggle]').on('click.buttonToggle', function () {
    const $this = $(this);
    const { buttonToggle: toggleClass, targetElement } = $this.data();

    $(targetElement).toggleClass(toggleClass);
  });
}

/**
 * custom scroll init
 */
function scrollInit() {
  const $target = $('[data-scroll]');
  
  $target.each(function () {
    $(this).mCustomScrollbar({ 
      theme: "minimal-dark",
      callbacks: {
        whileScrolling: function () {
          if (this.mcs.topPct === 100) {
            $(this).addClass('scroll-end');
          } else {
            $(this).removeClass('scroll-end');
          }
        },
      }
    });
  });
}

/**
 * 차량검색결과 클릭
 */
function carResultHandler() {
  const $lnb = $('[data-container=lnb]');
  const target = '[data-car]';

  $lnb.on('click', target, function () {
    const $this = $(this);
    const $tbody = $('[data-load="carType"]');
    const { car } = $this.data();
    const data = carStatusList[car];
    const replaceSrc = `/dist/images/ic-car-${car}.png`
    const $targetInfo = $('[data-info=car]');
    
    
    $(target).removeClass(activeClass);
    $this.addClass(activeClass);

    if (!$targetInfo) { return; }

    $tbody.empty();
    $targetInfo.find('img').attr('src', replaceSrc);
    $targetInfo.find('span').text($this.text());

    data.forEach((item) => {
      $tbody.append(`
        <tr>
          ${item.th1 && `<th>${item.th1}</th>`}
          ${item.td1 && `<td>${item.td1}</td>`}
          ${item.th2 && `<th>${item.th2}</th>`}
          ${item.td2 && `<td>${item.td2}</td>`}
        </tr>
      `);
    });
  });
}

/**
 * 맵 차량 선택
 */
function carSelect() {
  const $container = $('[data-container=carSelect]');
  const $target = $container.find('a');
  
  $target.on('click', function () {
    const $this = $(this);

    $container.find('li').removeClass('active');
    $this.parent('li').addClass('active');
  });
}

/**
 * poi 차량 이미지 토글
 */
function poiToggle() {
  const $target = $('[data-button=car]');

  $target.on('click', function () {
    const $img = $(this).find('img');
    const src = $img.attr('src');
    const isRest = src.indexOf('rest') > -1;
    const current = isRest ? src.replace('rest', 'working') : src.replace('working', 'rest')
    
    $img.attr('src', current);
  });
}

/**
 * tab toggle (수집정보 / 주행정보)
 */
function tabToggle() {
  const $container = $('[data-container=tab]');
  const $target = $container.find('a');

  $target.on('click', function () {
    const $this = $(this);
    const index = $this.parent('li').index();
    const $tabContainer = $('[data-container=tabContainer]');

    $target.removeClass('active');
    $this.addClass('active');
    $tabContainer.hide().eq(index).css('display', 'flex');
  });
}

/**
 * 이력관리 리스트 <-> 맵 토글
 * 2021.07 삭제
 */
// function viewToggle() {
//   const $target = $('[data-button=mapToggle]');

//   $target.on('click', function () {
//     const $this = $(this);
//     const isMapView = $this.hasClass('map-view');
//     const removeClass = isMapView ? 'map-view' : 'list-view';
//     const changeClass = isMapView ? 'list-view' : 'map-view';
//     const changeText = isMapView ? '목록보기' : '지도보기';
//     const $Container = $('[data-view]');
//     const $targetContainer = isMapView ? $('[data-view=map]') : $('[data-view=list]');
//     const $donwloadBtn = $('.etc-btn .btn-donwload');

//     $this.removeClass(removeClass).addClass(changeClass);
//     $this.find('span').text(changeText);
//     $Container.hide();
//     $targetContainer.css('display', 'flex');
//     isMapView ? $donwloadBtn.hide() : $donwloadBtn.show();
//   });
// }

/**
 * 실제 개발에 필요없는 귀차니즘으로 인한 소스
 */
function autoLabel() {
  const $checkbox = $('.chk');

  if (!$checkbox.length) { return; }

  $checkbox.each(function (index) {
    const $this = $(this);

    $this.attr('for', 'chk' + index)
    $this.prev('input').attr('id', 'chk' + index);
  });
}


/**
 * custom Selectbox
 */
function customSelectbox() {
  const $window = $(window);
  const dataAttrs = {
    container: 'data-container',
    button: 'data-button',
  }
  const $trigger = $(`[${dataAttrs.button}=selected]`);
  const hasDom = $trigger.length;

  hasDom && $trigger.off('click').on('click', function () {
    const $this = $(this);
    const $container = $this.closest($(`[${dataAttrs.container}=selectBox]`));
    const $optionBox = $container.find($(`[${dataAttrs.container}=selectOption]`));
    const $optionList = $optionBox.find('ul');
    const isActive = $container.hasClass(activeClass);
    const optionBoxHeight = $optionList.outerHeight();
    const windowHeight = $window.height();
    const optionOffset = $this.offset().top + $this.height();
    const isBottom = windowHeight - optionOffset < optionBoxHeight;

    if (!isActive) {
      $container.addClass(activeClass)
      $this.next().css({
        'top': isBottom ? 'auto' : '100%',
        'bottom': isBottom ? '100%' : 'auto',
        'height': optionBoxHeight,
      });

      $container.mouseleave(function () {
        $container.removeClass(activeClass);
        $this.next().css({
          'height': 0,
        });
      })

      // $optionList.find('input:checkbox').on('change', function () {
      //   const currentSelected = $this.find('span').text();
      //   const getText = $(this).next().text();
      //   if (getText === '전체') {
      //     const isChecked = $(this).prop('checked');
      //     $container.find('input:checkbox').prop('checked', isChecked);
      //     $this.find('span').text(isChecked ? '전체' : '선택하세요');
      //   } else {
      //     console.log(currentSelected);
      //   }
      // });
    } else {
      $container.removeClass(activeClass);
      $this.next().css({
        'height': 0,
      });
    }

  });
}

function gnbHandler() {
  const $gnbMenu = $('[data-gnb-tag=anchor]');
  const $header = $('header');

  $gnbMenu.on('mouseover', function () {
    const $this = $(this);

    $header.addClass('active');
    $gnbMenu.removeClass('hover');
    $this.addClass('hover');

    $header.on('mouseleave', function () {
      $header.removeClass('active');
      $gnbMenu.removeClass('hover');
    });
  });
}